<template>
  <div>
    <div @mousemove="swiperleft" @mouseup="mouseup" @mousedown="mousedown" style="cursor: pointer;">
      <div v-for="(item, i) in imglist" :key="i">
        <img
          draggable="false"
          :class="i === index ? 'show' : 'noshow'"
          :src="imglist[index]"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      index: 0,
      newindex: 0,
      trunc: 0,
      newx: 0,
      newx2: 0,
      zd: false,
      imglist: [],
      imgType: 'jpg',
      imgTypeArr: ['jpg', 'png', 'gif', 'webp', 'psd'],
      imgMaxNum: 100,
      goodsFileName: null,
      prefix: null
    };
  },
  mounted() {
    let that = this;
    document.title = '商品3D模型';
    const paramsOdj = that.getURLParams(window.location.href);
    that.goodsFileName = '';
    if (typeof(paramsOdj.fbxF) == 'undefined') {
        this.$message.error('缺少3D模型参数!');
        return ;
    }
    that.goodsFileName = paramsOdj.fbxF;
    
    if (typeof(paramsOdj.imgT) !== 'undefined' && that.imgTypeArr.includes(paramsOdj.imgT)) {
        that.imgType = paramsOdj.imgT
    }

    if (typeof(paramsOdj.prefix) !== 'undefined') {
        that.prefix = paramsOdj.prefix
    }

    if (typeof(paramsOdj.imgMaxNum) !== 'undefined') {
        that.imgMaxNum = paramsOdj.imgMaxNum
    }

    const imagePaths = [];
    for (var i = 0; i <= that.imgMaxNum; i++) {
      let num = String(i).padStart(4, "0");
      let srcStr = "./images/"+that.goodsFileName+"/" + that.prefix + num + "."+that.imgType;
      imagePaths.push(srcStr);
    }
    
    this.preloadImages(imagePaths);
  },
  
  created() {
  },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    trunc: function () {
      this.updateValue();
    },
  },
  methods: {
    preloadImages(imagePaths) {
      // 使用Promise.all预加载所有图片
      Promise.all(imagePaths.map(path => this.loadImage(path))).then(imageSrcs => {
        this.imglist = imageSrcs;
      });
    },
    loadImage(path) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(path);
        image.onerror = () => reject(new Error(`图片预加载失败: ${path}`));
        image.src = path;
      });
    },
    mousedown(e) {
      //按上开始滑动
      this.zd = true;
      //存储在x轴哪里开始点击的
      this.newx = e.x;
      //存储在点击的时候的图片是哪张
      this.newindex = this.index;
    },
    //松开不能滑动
    mouseup() {
      this.zd = false;
    },
    swiperleft(e) {
      //计算是往左滑动还是右面滑动
      this.newx2 = this.newx - e.x;
      //每30px滑动一张  在这里调节帧率 越小越流畅
      this.trunc = Math.trunc(this.newx2 / 10) + 1;
    },
    updateValue() {
      if (this.zd) {
        //当index大于数组长度了重置到第一张
        if (this.index > this.imglist.length - 1) {
          this.index = 0;
        } else {
          //判断是往左还是往右
          if (this.trunc > 0) {
            //往右
            this.index = this.newindex - (this.trunc % this.imglist.length);
            //滑动到index第一个了
            if (this.index < 1) {
              this.index = this.imglist.length - 1;
              this.newindex = this.imglist.length - 1;
            }
          } else {
            this.index = this.newindex - (this.trunc % this.imglist.length);
            //滑动到index最后一个了
            if (this.index > this.imglist.length - 1) {
              this.index = 0;
              this.newindex = 0;
            }
          }
        }
      }
    },
    // 获取路由的参数数据
    getURLParams(url) {
      var paramResult = {};
      let param = url.split("#")[0]; //获取#/之前的字符串
      let paramContent = "";
      let paramsArray = "";
      if (param) {
        paramContent = param.split("?")[1]; //获取?之后的参数字符串
      }
      if (paramContent) {
        paramsArray = paramContent.split("&"); //参数字符串分割为数组
        //遍历数组，拿到json对象
        paramsArray.forEach((item, index, paramsArray) => {
          paramResult[paramsArray[index].split("=")[0]] =
            paramsArray[index].split("=")[1];
        });
      }
      return paramResult;
    },
  },
};
</script>
  <style lang="less" scoped>
.show {
  opacity: 1;
  position: absolute;
  width: 800px;
}
.noshow {
  opacity: 0;
  position: absolute;
}
</style>