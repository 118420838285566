import Vue from 'vue'
import App from './App.vue'
import Route from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { loading } from "@/utils/elementUiLoading";

Vue.config.productionTip = false
// 使用Vue.use来安装插件
Vue.use(Route);
Vue.use(ElementUI);
Vue.prototype.toLoading = loading;
new Vue({
  //router,
  render: h => h(App),
}).$mount('#app')
