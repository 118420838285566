<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- 使用ThreeJs组件 -->
    <de3D />
  </div>
</template>

<script>
// 引入ThreeJs组件
//import ThreeJs from "./components/threejs.vue";
// import de3D from "./components/de3D.vue";
// import de3D from "./components/dragDe.vue";
// import de3D from "./components/imageSynthesis/imageSynthesis.vue";
import de3D from "./components/image3D.vue";
// import de3D from "./components/imageSynthesis/editImage.vue";
export default {
  name: 'App',
  components: {
    de3D
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
</style>
